<template>
  <modal name="alarm-auto-test" class="modal-inner modal-update-addon" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true" @before-close="resetAllTimers">
    <LoadingSpinner :isLoading="isLoading" v-if="isLoading" />
    <span class="close-button" @click="hide('alarm-auto-test')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">{{ $t("alarm_auto_test.test_device") }} - {{ alarmInfo.unit_name }}</div>
    <div class="modal-body">
      <ValidationObserver ref="AlarmAutoTest">
        <div class="p-4">
          <div class="bg-red-100 px-2 py-1 rounded-md mb-3 text-gray-600 text-xxs italic" v-if="showErrorMessage">{{ $t("alarm_auto_test.error_reset") }}</div>
          <div v-if="step === 1" class="">
            <p class="text-lg font-bold text-red-500">{{ $t("alarm_auto_test.test_mode_alert") }}</p>
            <p class="font-semibold mt-2">{{ $t("alarm_auto_test.alarm_auto_test") }}</p>
            <p class="mt-4">{{ $t("alarm_auto_test.alarm_phone_message") }}</p>
            <div class="mt-4">
              <ValidationProvider :name="$t('alarm_auto_test.alarm_phone')" rules="required" v-slot="{ classes, errors }">
                <div class="input-validate" :class="classes">
                  <input-mask type="text" v-model="alarm_mobile" mask="+46999999999" placeholder="+46766999222" class="w-full bg-gray-100 rounded text-sm text-gray-900 focus:outline-none border-b border-gray-300 transition duration-500 px-3 py-1"></input-mask>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div v-if="step === 2">
            <p class="text-center text-4xl text-gray-400">
              <BaseIcon icon="hand-point-down" class="text-red-500 ml-1 animate-bounce" v-if="testModeStatus == 'device_test_config'" />
              <BaseIcon icon="clock" spin class="ml-1" v-else-if="testModeStatus != ''" />
            </p>
            <p class="text-center italic text-sm font-serif mt-1" :class="{ 'text-red-500  animate-bounce': testModeStatus == 'device_test_config' }">
              {{ testModeStatusMessage }}
            </p>
            <hr class="mt-3">
            <p class="text-center text-xs mt-4">
              <span v-if="testEventRecieved" class=" text-green-400"><BaseIcon icon="check" class="mr-1" />{{ $t("alarm_auto_test.success_message") }}</span>
              <span v-else class=" text-red-400"><BaseIcon icon="times" class="mr-1" />{{ $t("alarm_auto_test.no_push_button") }}</span>
            </p>
            <p class="text-center text-xxs mt-3 mb-1 italic text-gray-300">{{ $t("alarm_auto_test.test_mobile_message") }} {{ alarm_mobile }}</p>
            <hr class="mt-3">
            <p v-if="timeLeft" class="text-center text-xs mt-3 text-gray-500">
              {{ $t("alarm_auto_test.remaining_time") }} <b class="text-sm my-1">{{ timeLeft }}</b><br>
              {{ $t("alarm_auto_test.remaining_time_success") }}
            </p>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer text-right p-3">
      <button v-if="step === 1" class="btn-blue-outline" @click="activateAlarmTesting()">
        <span style="font-size: 16px;">{{ $t("alarm_auto_test.active") }}</span>
      </button>
      <button v-if="step !== 1" class="btn-red" @click="resetAlarmTesting()">{{ $t("user_actions.deactivate") }}</button>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 600;

export default {
  props: {},
  data() {
    return {
      step: 1,
      testingIsActive: false,
      alarm_mobile: null,
      alarmInfo: { name: "" },
      device: null,
      gps: null,
      testModeStatus: '',
      testEventRecieved: false,
      showErrorMessage: false,
      timer: null,
      timeLeft: "",
      defaultDuration: 1200,
      duration: 20 * 60,
      intervalId: null, // To store the interval ID
      isLoading: false,
    };
  },

  components: {},

  methods: {
    setAlarmInfo(alarmInfo) {
      this.step = 1;
      this.alarmInfo = alarmInfo;

      this.alarm_mobile = this.alarmInfo.test_sms_number;

      // Get current time and previously stored values
      const now = this.moment.utc();
      const previousImei = this.alarmInfo.imei_number;
      const previousTime = this.alarmInfo.last_tunnel_disable;
      const lastTunnelDisableTimestamp = this.moment.utc(previousTime);

      // Calculate time difference in seconds
      const testingPeriodInSeconds = now.diff(lastTunnelDisableTimestamp, "seconds");

      // Check if the current IMEI matches the previous IMEI and if the testing period is within 20 minutes
      if (this.alarmInfo.imei_number === previousImei && testingPeriodInSeconds < this.defaultDuration) {
        // If testing is not active, initiate countdown
        if (!this.timeLeft) {
          this.duration = this.defaultDuration - testingPeriodInSeconds;
          this.startCountdown();
        }
        // Set step and testing status after ensuring UI updates
        this.$nextTick(() => {
          this.step = 2;
          this.testingIsActive = true;
        });
      } else {
        // Reset duration if conditions are not met
        this.duration = this.defaultDuration;
      }

      // Always check the alarm testing status at the end
      this.getAlarmTestingStatus();
    },

    async activateAlarmTesting() {
      this.$refs.AlarmAutoTest.validate().then(async (success) => {
        if (!success) {
          return;
        }
        this.testingIsActive = true;
        this.testEventRecieved = false;
        this.showErrorMessage = false;
        this.isLoading = true;

        try {
          let apiUrl = `${process.env.VUE_APP_SERVER_URL}/device-event-tunneling/disable/${this.alarmInfo.imei_number}`;
          let response = await this.axios.post(apiUrl, { notificationPhone: this.alarm_mobile });

          if (response.data.success) {
            this.device = response.data.device;
            this.gps = response.data.gps;
            this.step = 2;
            this.startCountdown();
            this.isLoading = false;

            this.$emit("updateAlarm");
          }
        } catch (error) {
          this.handleError(error);
          this.isLoading = false;
        }
      });
    },

    async resetAlarmTesting() {
      try {
        let apiUrl = `${process.env.VUE_APP_SERVER_URL}/device-event-tunneling/reset/${this.alarmInfo.imei_number}`;
        let response = await this.axios.get(apiUrl);

        if (response.data.success) {
          this.device = response.data.device;
          this.gps = response.data.gps;

          this.$emit("updateAlarm");
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    
    async getAlarmTestingStatus() {
      try {
        let apiUrl = `${process.env.VUE_APP_SERVER_URL}/device-event-tunneling/status/${this.alarmInfo.imei_number}`;
        let response = await this.axios.get(apiUrl);

        if (response.data.success) {
          this.device = response.data.device;
          this.gps = response.data.gps;

          this.testModeStatus = this.device.test_mode.status;

          let now = this.moment.utc(); // Represents the current time in UTC
          let lastTunnelDisableTimestamp = this.device.last_tunnel_disable;
          let testingPeriod = now.diff(lastTunnelDisableTimestamp, "seconds");

          // set relevant screen
          // '' = test mode is inactive
          if (this.testModeStatus == '') {
            this.testingIsActive = false;
            this.step = 1;
          }
          else if(testingPeriod <= this.defaultDuration) {
            this.testingIsActive = true;
            this.step = 2;
          }
          // todo: ?when timer expires

          // set push-button received event status
          let lastPushButtonTimestamp = this.moment.utc(this.gps.lastPushButtonTimestamp);
          let lastPushButton = now.diff(lastPushButtonTimestamp, "seconds");

          if (this.testModeStatus == 'recieved_event' ||
            (this.testingIsActive && this.defaultDuration >= testingPeriod && testingPeriod >= lastPushButton)) {
            this.testEventRecieved = true;
          }

          // in case deactivation is not successful
          if (this.testModeStatus == 'error') this.showErrorMessage = true;
          else if (this.testModeStatus == 'tunnel_enable' && !this.testingIsActive) this.showErrorMessage = true;
          else this.showErrorMessage = false;

        }
      } catch (error) {
        this.handleError(error);
      }
    },

    startInterval() {
      // Clear existing interval to avoid duplicates
      this.clearInterval();

      this.getAlarmTestingStatus();
      // Set up a new interval
      this.intervalId = setInterval(() => {
        this.getAlarmTestingStatus();
      }, 5000);
    },

    clearInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },

    startCountdown() {
      if (this.timer) {
        clearInterval(this.timer); // Clear existing timer if any
      }
      let secondsLeft = this.duration;
      this.updateTimeLeft(secondsLeft);

      this.timer = setInterval(() => {
        secondsLeft--;
        this.updateTimeLeft(secondsLeft);

        if (secondsLeft <= 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.timeLeft = null;
        }
      }, 1000);
    },

    updateTimeLeft(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secondsLeft = seconds % 60;
      this.timeLeft = `${minutes}m ${secondsLeft}s`;
    },

    resetAllTimers() {
      if (this.timer) {
        clearInterval(this.timer);
        
      }
      if (this.intervalId) {
        clearInterval(this.intervalId); // Clear the interval on component destroy
      }

      this.timer = null;
      this.intervalId = null;
      this.timeLeft = null;
      this.testingIsActive = false;
      this.testEventRecieved = false;
      this.showErrorMessage = false;
      this.testModeStatus = '';
      this.alarm_mobile = null;
      this.isLoading = false;
    },

    hide(id) {
      this.$modal.hide(id);
    },
  },

  mounted() {
    if (this.step === 2) {
      this.startInterval();
    }
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
  
  watch: {
    // Watch for changes on 'step'
    step(newVal) {
      if (newVal === 2) {
        this.startInterval();
      } else {
        this.clearInterval();
      }
    },
  },

  computed: {
    testModeStatusMessage() {
      let message = '';

      if(this.testModeStatus == 'tunnel_disable') message = this.$t("alarm_auto_test.tunnel_disable");
      else if(this.testModeStatus == 'device_query_success') message = this.$t("alarm_auto_test.device_query_success");
      else if(this.testModeStatus == 'device_test_config') message = this.$t("alarm_auto_test.device_test_config");
      else if(this.testModeStatus == 'recieved_event') message = this.$t("alarm_auto_test.recieved_event");
      else if(this.testModeStatus == 'tunnel_enable') message = this.$t("alarm_auto_test.tunnel_enable");
      else if(this.testModeStatus == 'error') message = this.$t("alarm_auto_test.error");

      return message
    },
  },

  beforeDestroy() {
    this.resetAllTimers();
  },
};
</script>
